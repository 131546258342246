import React, { Component } from "react";
import _ from "lodash";
import htmlToReact from "../utils/htmlToReact";

export default class HeroTitle extends Component {
  render() {
    return (
      <header className="hero-title">
        <h1 className="hero-title__heading">
          {_.get(this.props, "pageContext.frontmatter.title")}
        </h1>
        {_.get(this.props, "pageContext.frontmatter.subtitle") && (
          <h3>
            {htmlToReact(_.get(this.props, "pageContext.frontmatter.subtitle"))}
          </h3>
        )}
      </header>
    );
  }
}

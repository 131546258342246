import React from "react";
import _ from "lodash";
import ReactMarkdown from "react-markdown";
import HeroTitle from "../components/HeroTitle";
import { Layout } from "../components/index";

export default class Booking extends React.Component {
  componentDidMount() {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    script.setAttribute("defer", "");
    head.appendChild(script);
    console.log("done");
  }

  callCalendar(e, duration) {
    e.preventDefault();
    window.Calendly.initPopupWidget({
      url: `https://calendly.com/richardlatimer/${duration}-min`
    });
  }

  render() {
    return (
      <Layout {...this.props}>
        <div className="page">
          <div className="p-strip is-deep">
            <HeroTitle {...this.props} />
            <h3>Find an appointment which suits you.</h3>
            {this.props.pageContext.site.data.notice.showNotice && (
              <div className="notice-strip">
                <div className="notice-strip__inner">
                  <div className="notice">
                    <div className="notice__icon">
                      <img src="/images/announce.svg" width="50" alt="" />
                    </div>
                    <div className="notice__text">
                      <ReactMarkdown
                        source={_.get(
                          this.props,
                          "pageContext.site.data.notice.notice"
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="booking-grid">
            <div className="booking-grid__item">
              <div className="booking-grid__desc">
                <h2 className="p-heading--three">30 minutes</h2>
                <p>
                  Ideal if you are short on time. Regularly booked for a back,
                  neck and shoulder massage, or for work on the legs. Perfect to
                  release muscular tension in those areas.
                </p>
              </div>
              <div className="booking-grid__desc">
                <button
                  className="button--primary"
                  onClick={e => this.callCalendar(e, "30")}
                >
                  Book 30 mins
                </button>
              </div>
            </div>

            <div className="booking-grid__item">
              <div className="booking-grid__desc">
                <h2 className="p-heading--three">45 minutes</h2>
                <p>
                  I can deal with the full body in this time. A typical session
                  would involve deep tissue massage on your legs (ideal for
                  runners, sports people &amp; gym users etc) as well as the
                  entire back, neck and shoulders.
                </p>
              </div>
              <div className="booking-grid__desc">
                <button
                  className="button--primary"
                  onClick={e => this.callCalendar(e, "45")}
                >
                  Book 45 mins
                </button>
              </div>
            </div>

            <div className="booking-grid__item">
              <div className="booking-grid__desc">
                <h2 className="p-heading--three pop-label">60 minutes</h2>
                <p>
                  Time to fully unwind and relax. I can work your full body,
                  taking time on each area - allowing you to enjoy the full
                  benefits of my experienced massage therapy.
                </p>
              </div>
              <div className="booking-grid__desc">
                <button
                  className="button--primary"
                  onClick={e => this.callCalendar(e, "60")}
                >
                  Book 60 mins
                </button>
              </div>
            </div>

            <div className="booking-grid__item">
              <div className="booking-grid__desc">
                <h2 className="p-heading--three">90 minutes</h2>
                <p>
                  Complete relaxation, after which you will feel rejuvinated and
                  any muscular tension you are experiencing will be eased away.
                </p>
              </div>
              <div className="booking-grid__desc">
                <button
                  className="button--primary"
                  onClick={e => this.callCalendar(e, "90")}
                >
                  Book 90 mins
                </button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
